import { useMainStore } from '~/stores/main'

export default function () {
  const { origin } = useMainStore()
  const runtimeConfig = useRuntimeConfig()
  const recaptcha = useVueRecaptcha()

  const disableRecaptchaList = (runtimeConfig.public.elDisableRecaptcha || '')
    .split(',')
    .filter(Boolean)
    .map((s) => `elqFormName=${s.trim()}`)

  const getRecaptchaToken = async (action: string, eloquaFormData: string) => {
    if (disableRecaptchaList && disableRecaptchaList.length) {
      const formName = eloquaFormData
        .split('&')
        .find((s) => s.includes('elqFormName'))
      if (formName && disableRecaptchaList.includes(formName)) {
        return undefined
      }
    }

    return await recaptcha(action)
  }

  return {
    submit: async (action: string, eloquaFormData: string) => {
      const token = await getRecaptchaToken(action, eloquaFormData)
      const config = { data: eloquaFormData }

      return await $fetch(`${origin}/api/eloqua-form`, {
        method: 'POST',
        params: { action },
        body: {
          token,
          config
        }
      })
    }
  }
}
