import { useReCaptcha } from 'vue-recaptcha-v3'

export default function () {
  const recaptchaInstance = useReCaptcha()

  return async (action: string) => {
    await recaptchaInstance?.recaptchaLoaded()
    return recaptchaInstance?.executeRecaptcha(action)
  }
}
